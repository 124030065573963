import { gql } from "@apollo/client";

export const WIDGET_QUERY = gql`
  query widget {
    widget {
      primaryColor
      flagShape
      font
      hasFlags
      hasReviewDate
      hasReviewPhotos
      hasVerifiedBadge
      ratingIcon
      ratingIconColor
      reviewDateFormat
      verifiedBadgeColor
      translation {
        language
        item
      }
    }
  }
`;

export const UPDATE_WIDGET_MUTATION = gql`
  mutation updateWidget($input: UpdateWidgetInput!) {
    updateWidget(input: $input)
  }
`;
