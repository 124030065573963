import { gql } from '@apollo/client';

export const QUERY_PRODUCTS = gql`
  query products($query: String, $after: String) {
    products(query: $query, after: $after) {
      after
      hasNextPage
      nodes {
        id
        title
        image
        handle
        reviewsCount
      }
    }
  }
`;

export const DELETE_REVIEWS = gql`
  mutation deleteReviewsByProduct($id: ID!) {
    deleteReviewsByProduct(id: $id)
  }
`;

export const UPDATE_REVIEWS_STATUS = gql`
  mutation updateReviewsStatusByProduct($input: UpdateReviewsStatusByProductInput) {
    updateReviewsStatusByProduct(input: $input)
  }
`;
