import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Widget, UpdateWidgetInput } from "base/graphql/types";

export interface IWidgetState {
  isUpdating: boolean;
  isGetting: boolean;
  widget?: Widget;
}

const initialState: IWidgetState = {
  isUpdating: false,
  isGetting: false,
};

const widgetSlice = createSlice({
  name: "widgetSlice",
  initialState,
  reducers: {
    startGettingWidget: (state) => {
      state.isGetting = true;
    },
    getWidgetSuccess: (state, action: PayloadAction<Widget>) => {
      state.isGetting = false;
      state.widget = action.payload;
    },
    getWidgetFail: (state) => {
      state.isGetting = false;
    },

    startUpdatingWidget: (state, _action: PayloadAction<UpdateWidgetInput>) => {
      state.isUpdating = true;
    },
    updateWidgetSuccess: (state) => {
      state.isUpdating = false;
    },
    updateWidgetFail: (state) => {
      state.isUpdating = false;
    },
  },
});

export const { reducer, actions } = widgetSlice;
export const { startGettingWidget, startUpdatingWidget } = actions;
