import { put, takeLatest } from 'redux-saga/effects';

import { mutate, query } from 'base/services/graphql';

import type { PayloadAction } from '@reduxjs/toolkit';
import { getError } from 'base/helpers/error';
import type { IPagination } from 'base/services/pagination';
import { showToast } from 'business/toast/slice';
import { actions } from './slice';
import { UPDATE_WORKSPACE_MUTATION, WORKSPACE_QUERY } from './consts';
import type { IUpdateWorkspaceInput, IWorkspace } from './consts';
import type { IUpdateWorkspaceFormValues } from './WorkspaceSettingForm/components/UpdateWorkspaceForm/consts';

function* getWorkspace() {
  try {
    const { data } = yield query<IPagination<IWorkspace>>({ 
      query: WORKSPACE_QUERY,
    });
    yield put(actions.getWorkspaceSuccess(data.workspace));
  } catch (e) {
    yield put(actions.getWorkspaceFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateWorkspace({ payload }: PayloadAction<IUpdateWorkspaceFormValues>) {
  try {
    yield mutate<boolean, { input: IUpdateWorkspaceInput }>({ 
      mutation: UPDATE_WORKSPACE_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateWorkspaceSuccess());
    yield put(showToast({ content: 'workspaceEdited' }));
  } catch (e) {
    yield put(actions.updateWorkspaceFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startUpdatingWorkspace, updateWorkspace);
  yield takeLatest(actions.startGettingWorkspace, getWorkspace);
}
