import { GLOBAL_CONST } from 'base/consts/global';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';

export const cleanObject = <T>(source: T): T => {
  const object: any = cloneDeep(source);

  Object.keys(object).forEach((key) => {
    const value = object[key];

    // Remove falsy values (except 0)
    if (!value && value !== 0 && value !== false) {
      delete object[key];
      return;
    }

    // Remove empty Array []
    if (Array.isArray(value) && value.length === 0) {
      delete object[key];
      return;
    }

    // Remove empty Object {}
    if (Object.keys(value).length === 0 && value.constructor === Object) {
      delete object[key];
      return;
    }

    // trim String
    if (typeof value === 'string') {
      object[key] = value.trim();
    }
  });

  return object;
};

export const convertTimeNumberToString = (raw: number): string => {
  if (raw < 10) return `0${raw.toString()}`;
  return raw.toString();
};

export const formatDateTime = (
  raw: Date | string | number,
  format: string
): string => {
  if (!raw) return '';

  return moment(new Date(raw)).format(format);
};

export const mergeReducer = <T>(currentState: T, newState: T): T => {
  return { ...currentState, ...newState };
};

export const formatStringMoment = (
  raw: string,
  format = GLOBAL_CONST.FORMAT.DATE_AND_TIME_DATEPICKER
): Moment => {
  return moment(raw, format, true);
};
