import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RatingConfig, UpdateRatingConfigInput } from 'base/graphql/types';

export interface IConfigState {
  isUpdating: boolean;
  isGetting: boolean;
  rating?: RatingConfig;
}

const initialState: IConfigState = {
  isUpdating: false,
  isGetting: false,
};

const ratingSlice = createSlice({
  name: 'ratingSlice',
  initialState,
  reducers: {
    startGettingConfig: (state) => {
      state.isGetting = true;
    },
    getConfigSuccess: (state, action: PayloadAction<RatingConfig>) => {
      state.isGetting = false;
      state.rating = action.payload;
    },
    getConfigFail: (state) => {
      state.isGetting = false;
    },
    startUpdatingConfig: (
      state,
      _action: PayloadAction<UpdateRatingConfigInput>
    ) => {
      state.isUpdating = true;
    },
    updateConfigSuccess: (state) => {
      state.isUpdating = false;
    },
    updateConfigFail: (state) => {
      state.isUpdating = false;
    },
  },
});

export const { reducer, actions } = ratingSlice;
export const { startGettingConfig, startUpdatingConfig } = actions;
