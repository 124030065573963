import { combineReducers } from 'redux';

import { reducer as toastReducer } from 'business/toast/slice';
import { reducer as workspaceReducer } from 'business/workspace/slice';
import { reducer as dashboardReducer } from 'business/dashboard/slice';
import { reducer as userReducer } from 'business/account/slice';
import { reducer as shopifyReducer } from 'business/shopify/slice';
import { reducer as reviewReducer } from 'business/reviews/slice';
import { reducer as reviewImportReducer } from 'business/reviews/import/slice';
import { reducer as widgetReducer } from 'business/branding/widget/slice';
import { reducer as ratingReducer } from 'business/branding/rating/slice';
import { reducer as showcaseReducer } from 'business/branding/showcase/slice';
import { reducer as popupReducer } from 'business/branding/popup/slice';
import { reducer as productReducer } from 'business/products/slice';
import { routerReducer } from 'connected-next-router';

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  toast: toastReducer,
  router: routerReducer,
  workspace: workspaceReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  shopify: shopifyReducer,
  review: reviewReducer,
  reviewImport: reviewImportReducer,
  widget: widgetReducer,
  rating: ratingReducer,
  showcase: showcaseReducer,
  popup: popupReducer,
  product: productReducer,
});
