import type { ApolloQueryResult } from '@apollo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateImportReviewInput,
  CreateReviewInput,
  ImportReviewConnection,
  Mutation,
  MutationCreateImportReviewArgs,
  MutationCreateReviewArgs,
  Query,
  QueryReviewImportArgs,
  ReviewImportSourceEnum,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { cleanObject } from 'base/helpers/extra';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { push } from 'connected-next-router';
import { put, takeLatest } from 'redux-saga/effects';
import { IMPORT_TAB_STATUS } from './const';
import { CREATE_IMPORT_MUTATION, REVIEW_IMPORT_QUERY } from './graphql';
import { actions, IReviewImportParams } from './slice';

function* getListReviewInitSaga({
  payload,
}: PayloadAction<IReviewImportParams>) {
  const { statusTabIndex } = payload || {};
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ImportReviewConnection,
      QueryReviewImportArgs
    >({
      query: REVIEW_IMPORT_QUERY,
      variables: cleanObject({
        status: IMPORT_TAB_STATUS[statusTabIndex],
      }),
    });
    yield put(actions.getListImportReviewSuccess(data.reviewImport));
  } catch (e) {
    yield put(actions.getListImportReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* createImportReviewSaga({
  payload,
}: PayloadAction<CreateImportReviewInput>) {
  const { source } = payload;
  try {
    const { data }: ApolloQueryResult<Mutation> = yield mutate<
      Mutation,
      MutationCreateImportReviewArgs
    >({
      mutation: CREATE_IMPORT_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.createImportSuccess());
    yield put(showToast({ content: 'reviewCreated' }));
    if (
      [
        ReviewImportSourceEnum.Amazon,
        ReviewImportSourceEnum.AliExpress,
      ].includes(source)
    ) {
      yield put(push(`/reviews/import`));
    }
  } catch (e) {
    yield put(actions.createImportSuccess());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startCreatingImport, createImportReviewSaga);
  yield takeLatest(actions.startGettingListImportReview, getListReviewInitSaga);
}
