import { ApolloQueryResult } from "@apollo/client";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Query, QueryShopifyCollectionsArgs, QueryShopifyProductsArgs } from "base/graphql/types";
import { getError } from "base/helpers/error";
import { query } from "base/services/graphql";
import { showToast } from "business/toast/slice";
import { put, takeLatest } from "redux-saga/effects";
import { SHOPIFY_COLLECTIONS_QUERY, SHOPIFY_PRODUCTS_QUERY } from "./graphql";
import { actions } from "./slice";
import type { IStartPaginatingCollectionsPayload, IStartPaginatingProductsPayload } from "./types";

function* paginateShopifyProducts({ payload }: PayloadAction<IStartPaginatingProductsPayload>) { 
  try {
    const { data }: ApolloQueryResult<Query> = yield query<Query, QueryShopifyProductsArgs>({
      query: SHOPIFY_PRODUCTS_QUERY,
      variables: { after: payload.after, query: payload.query },
    });
    yield put(actions.paginateShopifyProductsSuccess({ ...data.shopifyProducts, initial: payload.initial }));
  } catch (e) {
    yield put(actions.paginateShopifyProductsFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* paginateShopifyCollections({ payload }: PayloadAction<IStartPaginatingCollectionsPayload>) { 
  try {
    const { data }: ApolloQueryResult<Query> = yield query<Query, QueryShopifyCollectionsArgs>({
      query: SHOPIFY_COLLECTIONS_QUERY,
      variables: { after: payload.after, query: payload.query },
    });
    yield put(actions.paginateShopifyCollectionsSuccess({ ...data.shopifyCollections, initial: payload.initial }));
  } catch (e) {
    yield put(actions.paginateShopifyCollectionsFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startPaginatingShopifyProducts, paginateShopifyProducts);
  yield takeLatest(actions.startPaginatingShopifyCollections, paginateShopifyCollections);
}
