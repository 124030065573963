import { Toast } from "@shopify/polaris";
import type { RootState } from "base/redux/reducer";
import useTranslation from "next-translate/useTranslation";
import type { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissToast } from "../slice";

export const ToastManager: FC = () => {
  const { t: translate } = useTranslation('toast');

  const toastReducer = useSelector((state: RootState) => state.toast);

  const dispatch = useDispatch();

  return (
    <>
      {toastReducer.toasts.map(({ id, useTranslation, ...toast }) => (
        <Toast
          {...toast}
          content={useTranslation ? translate(toast.content) : toast.content}
          key={id}
          onDismiss={() => dispatch(dismissToast(id))}
        />
      ))}
    </>
  );
};
