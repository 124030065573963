export const openChat = () => {
  if (!window.Beacon) return;
  window.Beacon('open');
}

export const hideChat = () => {
  if (!window.Beacon) return;
  window.Beacon('close');
}

export const showChat = () => {
  if (!window.Beacon) return;
  window.Beacon('open');
}

export const setUpChat = () => {
  if (!window.Beacon) return;
  window.Beacon('init', window.workspace.token);
  window.Beacon('identify', {
    email: window.workspace.userEmail,
    name: window.workspace.userName,
    app: 'Product Reviews',
    shop: window.workspace.shopifyShop,
    plan: window.workspace.workspacePlan,
  });
}
