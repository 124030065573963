import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { ToastProps } from '@shopify/polaris';
import { nanoid } from 'nanoid';

export interface IToast extends Omit<ToastProps, 'onDismiss' | 'action'> {
  id: string;
  useTranslation?: boolean;
}

export interface IToastState {
  toasts: IToast[];
}

const initialState: IToastState = {
  toasts: []
};

const toastSlice = createSlice({
  name: 'toastSlice',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<Omit<IToast, 'id'>>) => {
      const toast = action.payload;
      state.toasts = [
        ...state.toasts,
        { ...toast, id: nanoid(), duration: toast.duration || 5000, useTranslation: toast.useTranslation || true }
      ];
    },
    dismissToast: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.toasts = state.toasts.filter(toast => toast.id !== id);
    }
  },
});

export const { reducer, actions } = toastSlice;
export const { showToast, dismissToast } = actions;
