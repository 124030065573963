import type { LinkLikeComponentProps } from "@shopify/polaris/build/ts/latest/src/utilities/link"
import type { FC } from "react"
import NextLink from 'next/link';

export const Link: FC<LinkLikeComponentProps> = (props) => {
  const { children, url, external, ...rest } = props

  return (
    <NextLink href={url}>
      <a {...rest} target={external ? '_blank' : '_self'}>{children}</a>
    </NextLink>
  )
}