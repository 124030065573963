export const GLOBAL_CONST = {
  FORMAT: {
    DATE_ONLY: 'DD/MM/YYYY',
    DATE_REVERSED: 'YYYY-MM-DD',
    DATE_AND_TIME: 'DD/MM/YYYY HH:mm:ss',
    DATE_AND_TIME_DATEPICKER: 'DD/MM/YYYY | HH:mm:ss',
    ISO_DATE_ONLY: 'YYYY-MM-DD',
    ISO_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss.sssZ',
    ISO_DATE_INTEGER: 'YYYYMMDD',
    LOCALIZED_FORMAT: 'lll',
  },
};
