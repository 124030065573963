import { put, takeLatest } from 'redux-saga/effects';
import { mutate, query } from 'base/services/graphql';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getError } from 'base/helpers/error';
import { showToast } from 'business/toast/slice';
import { actions } from './slice';
import { UPDATE_USER_MUTATION, USER_QUERY } from './graphql';
import type { MutationUpdateUserArgs, Query, UpdateUserInput, User } from 'base/graphql/types';
import type { ApolloQueryResult } from '@apollo/client';

function* getUser() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<User>({ 
      query: USER_QUERY,
    });
    yield put(actions.getUserSuccess(data.user));
  } catch (e) {
    yield put(actions.getUserFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateUser({ payload }: PayloadAction<UpdateUserInput>) {
  try {
    yield mutate<boolean, MutationUpdateUserArgs>({ 
      mutation: UPDATE_USER_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateUserSuccess());
    yield put(showToast({ content: 'userEdited' }));
  } catch (e) {
    yield put(actions.updateUserFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startUpdatingUser, updateUser);
  yield takeLatest(actions.startGettingUser, getUser);
}
