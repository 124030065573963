import { gql } from "@apollo/client";

export const SHOPIFY_PRODUCTS_QUERY = gql`
  query shopifyProducts ($after: String, $query: String) {
    shopifyProducts (after: $after, query: $query) {
      after
      items {
        id
        title
        image
        handle
      }
      hasNextPage
    }
  }
`

export const SHOPIFY_COLLECTIONS_QUERY = gql`
  query shopifyCollections ($after: String, $query: String) {
    shopifyCollections (after: $after, query: $query) {
      after
      items {
        id
        title
        image
        handle
      }
      hasNextPage
    }
  }
`
