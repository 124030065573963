
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "../styles/globals.scss";
import type { AppProps } from "next/app";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Frame } from "@shopify/polaris";
import { MainLayout } from "base/components/layout/mainLayout";
import { useCallback } from "react";
import type { FC } from "react";
import { Link } from "base/components/Link";
import { useRouter } from "next/router";
import { wrapperStore } from "base/redux/store";
import { ConnectedRouter } from "connected-next-router";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "base/components/ErrorPage";
import { UserProvider } from "base/context/user";
import Head from "next/head";
import { APP_CONFIG } from "base/consts/app";

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { pathname } = useRouter();

  const isAuthPage = pathname.startsWith("/auth");

  const onError = useCallback((error: Error) => {
    console.error(error);
  }, []);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://loy-static.hoora.io/images/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://loy-static.hoora.io/images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://loy-static.hoora.io/images/favicon/favicon-16x16.png"
        />
        <title>{APP_CONFIG.APP_NAME}</title>
      </Head>
      <UserProvider>
        {/* @ts-ignore */}
        <AppProvider i18n={enTranslations} linkComponent={Link}>
          <ConnectedRouter>
            <div className="custom-properties">
                {/* @ts-ignore */}
              <ErrorBoundary FallbackComponent={<ErrorPage />} onError={onError}>
                {isAuthPage ? (
                  <Frame>
                    <Component {...pageProps} />
                  </Frame>
                ) : (
                  <MainLayout>
                    <Component {...pageProps} />
                  </MainLayout>
                )}
              </ErrorBoundary>
            </div>
          </ConnectedRouter>
        </AppProvider>
      </UserProvider>
    </>
  );
};

const __Page_Next_Translate__ = wrapperStore.withRedux(App);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  