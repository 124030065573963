import type { ApolloQueryResult } from '@apollo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Mutation,
  MutationUpdateRatingConfigArgs,
  Query,
  RatingConfig,
  UpdateRatingConfigInput,
  Widget,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { put, takeLatest } from 'redux-saga/effects';
import { RATING_CONFIG_QUERY, UPDATE_CONFIG_MUTATION } from './graphql';
import { actions } from './slice';

function* getConfig() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<RatingConfig>({
      query: RATING_CONFIG_QUERY,
    });
    yield put(actions.getConfigSuccess(data.rating as RatingConfig));
  } catch (e) {
    yield put(actions.getConfigFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateConfig({ payload }: PayloadAction<UpdateRatingConfigInput>) {
  try {
    yield mutate<Mutation, MutationUpdateRatingConfigArgs>({
      mutation: UPDATE_CONFIG_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateConfigSuccess());
    yield put(showToast({ content: 'ratingConfigUpdated' }));
  } catch (e) {
    yield put(actions.updateConfigFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingConfig, getConfig);
  yield takeLatest(actions.startUpdatingConfig, updateConfig);
}
