import { Frame } from '@shopify/polaris';
import { ToastManager } from 'business/toast/ToastManager';
import { useRouter } from 'next/router';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, useRef, useState } from 'react';
import { POLARIS_CONFIG } from '../../../consts/polaris';
import { Navigation } from './components/navigation';
import { TopBar } from './components/topBar';

const hideNavigationPaths = [
  '/email/remind',
  '/email/request',
  '/email/thank',
  '/branding/widget',
  '/branding/rating',
  '/branding/home-showcase',
  '/branding/page-showcase',
  '/branding/popup',
];

const hideTopBarPaths = [
  '/email/remind',
  '/email/request',
  '/email/thank',
  '/branding/widget',
  '/branding/rating',
  '/branding/page-showcase',
  '/branding/home-showcase',
  '/branding/popup',
];

export const MainLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const skipToContentRef = useRef<HTMLAnchorElement>(null);

  const { pathname } = useRouter();

  const [mobileNavigation, setMobileNavigation] = useState(false);

  const onNavigationToggle = useCallback(
    () => setMobileNavigation((mobileNavigation) => !mobileNavigation),
    []
  );

  return (
    <>
      <Frame
        logo={POLARIS_CONFIG}
        topBar={
          !hideTopBarPaths.includes(pathname) && (
            <TopBar onNavigationToggle={onNavigationToggle} />
          )
        }
        navigation={!hideNavigationPaths.includes(pathname) && <Navigation />}
        showMobileNavigation={mobileNavigation}
        onNavigationDismiss={onNavigationToggle}
        skipToContentTarget={skipToContentRef}
      >
        <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
        {children}
        <ToastManager />
      </Frame>
      <style jsx global>{`
        .workspace-switcher-wrapper {
          margin: 0 1rem 1rem;
        }
        .Polaris-Navigation__Item {
          font-weight: var(--p-font-weight-semibold);
        }
      `}</style>
    </>
  );
};
