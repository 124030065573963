import { useCallback, useState } from "react";
import type { FC } from "react";
import { TopBar as PolarisTopBar } from '@shopify/polaris';
import type { IconableAction } from '@shopify/polaris';
import { LogOutMinor, ProfileMinor, OnlineStoreMajor } from "@shopify/polaris-icons";
import useTranslation from "next-translate/useTranslation";
import { useModal } from "base/hooks/useModal";
import { ConfirmModal } from "base/components/modals/confirmModal";
import { firebaseAuth } from "base/services/firebase";
import { useDispatch } from "react-redux";
import { showToast } from "business/toast/slice";
import { useUserContext } from "base/context/user";
import { APP_CONFIG } from 'base/consts/app';
import { openChat } from "base/helpers/chat";

export interface ITopBarProps {
  onNavigationToggle: () => void;
}

export const TopBar: FC<ITopBarProps> = ({ onNavigationToggle }) => {
  const { t: translate } = useTranslation("common");
  const { t: logoutTranslate } = useTranslation("logout");
  const { t: buttonTranslate } = useTranslation("button");

  const dispatch = useDispatch();

  const { shopifyShop } = useUserContext();

  const [isLogoutModal, onCloseLogoutModal, onOpenLogoutModal] = useModal();

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const onLogout = useCallback(() => {
    setIsLoggingOut(true);
    firebaseAuth.signOut()
      .then(() => {
        dispatch(showToast(translate('logoutSuccess')))
      })
      .catch(error => {
        dispatch(showToast({
          content: error.message,
          error: true,
          useTranslation: false
        }))
      })
      .finally(() => setIsLoggingOut(false))
  }, []);

  const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);

  const userContext = useUserContext();

  const userMenuActions: { items: IconableAction[] }[] = [
    {
      items: [
        {
          content: translate("manageAccount"),
          url: "/settings/account",
          icon: ProfileMinor,
        },
        { content: translate("logout"), icon: LogOutMinor, onAction: onOpenLogoutModal },
      ],
    },
    {
      items: [
        {
          content: translate("onlineStore"),
          url: `https://${shopifyShop}`,
          external: true,
          icon: OnlineStoreMajor,
        },
      ],
    },
    {
      items: [
        {
          content: translate("helpCenter"),
          url: APP_CONFIG.HELP_CENTER_URL,
          external: true,
        },
        {
          content: translate("liveChat"),
          onAction: openChat
        },
      ],
    },
  ];

  const userMenuMarkup = (
    <PolarisTopBar.UserMenu
      actions={userMenuActions}
      name={userContext?.userName || ''}
      detail={userContext?.workspaceName || ''}
      initials={userContext?.userName?.[0] || ''}
      avatar={userContext?.userAvatar as string}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  return (
    <>
      <PolarisTopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        onNavigationToggle={onNavigationToggle}
      />
      <ConfirmModal
        confirmText={buttonTranslate("confirm")}
        title={logoutTranslate("logoutModalTitle")}
        onAction={onLogout}
        message={logoutTranslate("logoutMessage")}
        open={isLogoutModal}
        onClose={onCloseLogoutModal}
        loading={isLoggingOut}
        isDestructive
      />
    </>
  )
}
