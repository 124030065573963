import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { IWorkspace } from './consts';
import type { IUpdateWorkspaceFormValues } from './WorkspaceSettingForm/components/UpdateWorkspaceForm/consts';

export interface IWorkspaceState {
  isUpdating: boolean;
  isGetting: boolean;
  workspace?: IWorkspace;
}

const initialState: IWorkspaceState = {
  isUpdating: false,
  isGetting: false,
};

const workspaceSlice = createSlice({
  name: 'workspaceSlice',
  initialState,
  reducers: {
    startGettingWorkspace: (state) => {
      state.isGetting = true;
    },
    getWorkspaceSuccess: (state, action: PayloadAction<IWorkspace>) => {
      state.isGetting = false;
      state.workspace = action.payload;
    },
    getWorkspaceFail: state => {
      state.isGetting = false
    },

    startUpdatingWorkspace: (state, _action: PayloadAction<IUpdateWorkspaceFormValues>) => {
      state.isUpdating = true;
    },
    updateWorkspaceSuccess: (state) => {
      state.isUpdating = false;
    },
    updateWorkspaceFail: state => {
      state.isUpdating = false
    },
  },
});

export const { reducer, actions } = workspaceSlice;
export const { startGettingWorkspace, startUpdatingWorkspace } = actions;
