import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`;

export const USER_QUERY = gql`
  query user {
    user {
      avatar
      email
      firstName
      lastName
      position
    }
  }
`;
