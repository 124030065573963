import { gql } from '@apollo/client';

export const PAGE_SHOWCASE_QUERY = gql`
  query pageShowcase {
    pageShowcase {
      pageName
      primaryColor
      flagShape
      font
      hasFlags
      hasReviewDate
      hasReviewPhotos
      hasVerifiedBadge
      ratingIcon
      ratingIconColor
      reviewDateFormat
      verifiedBadgeColor
      source
      productId
      reviewId
      extra
    }
  }
`;

export const HOME_SHOWCASE_QUERY = gql`
  query homeShowcase {
    homeShowcase {
      primaryColor
      flagShape
      font
      hasFlags
      hasReviewDate
      hasReviewPhotos
      hasVerifiedBadge
      ratingIcon
      ratingIconColor
      reviewDateFormat
      verifiedBadgeColor
      source
      productId
      reviewId
      extra
    }
  }
`;

export const UPDATE_PAGE_SHOWCASE_MUTATION = gql`
  mutation updatePageShowcase($input: UpdatePageShowcaseInput!) {
    updatePageShowcase(input: $input)
  }
`;

export const UPDATE_HOME_SHOWCASE_MUTATION = gql`
  mutation updateHomeShowcase($input: UpdateHomeShowcaseInput!) {
    updateHomeShowcase(input: $input)
  }
`;
