import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  Product,
  ProductConnection,
  ReviewStatusEnum,
} from 'base/graphql/types';

import { uniqBy } from 'lodash';

export interface IParams {
  status: string;
  title: string;
}

export interface IUpdateReviewByProduct {
  id: string;
  status: ReviewStatusEnum;
}

interface IStateProps {
  after?: string | undefined;
  params: IParams;
  products: Product[];
  hasNextPage?: boolean;
  isGettingList?: boolean;
  isGettingError?: boolean;
  isDeleteDeleting?: boolean;
  isDeleteSuccess?: boolean;
  isChangeStatusSuccess?: boolean;
  isExporting: boolean;
}

const initialState: IStateProps = {
  isExporting: false,
  isGettingList: false,
  isGettingError: false,
  isDeleteDeleting: false,
  isDeleteSuccess: false,
  isChangeStatusSuccess: false,
  hasNextPage: false,
  after: '',
  products: [],
  params: {
    status: '',
    title: ''
  },
};

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {
    startPaginateProducts: (
      state,
      _action: PayloadAction<string | undefined>
    ) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    paginateProductSuccess: (
      state,
      action: PayloadAction<ProductConnection>
    ) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, after } = action.payload;
      state.products = nodes?.length
        ? uniqBy([...state.products, ...nodes], 'id')
        : [];
      state.hasNextPage = hasNextPage;
      state.after = after;
    },
    paginateProductFail: (state) => {
      state.isGettingList = false;
      state.isGettingError = true;
    },
    // handle params
    handlePageParams: (state, action: PayloadAction<IParams>) => {
      state.params = action.payload;
    },
    startGettingListProductInit: (state) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    getListProductInitSuccess: (
      state,
      action: PayloadAction<ProductConnection>
    ) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, after } = action.payload;
      state.products = nodes;
      // state.filteredProduct = nodes;
      state.hasNextPage = hasNextPage;
      state.after = after;
    },
    getListProductInitFail: (state) => {
      state.isGettingError = true;
      state.isGettingList = false;
    },

    startFilterProduct: (state, _action: PayloadAction<IParams>) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    // filter product
    filterProductSuccess: (state, action: PayloadAction<ProductConnection>) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, after } = action.payload;
      state.products = nodes;
      state.hasNextPage = hasNextPage;
      state.after = after;
    },
    filterProductFail: (state) => {
      state.isGettingList = false;
      state.isGettingError = true;
    },
    // delete review of product
    startDeleteProductReviews: (state, _action: PayloadAction<string>) => {
      state.isDeleteDeleting = true;
      state.isGettingError = false;
      state.isDeleteSuccess = false;
    },
    deleteProductReviewSuccess: (state, action: PayloadAction<string>) => {
      state.isDeleteDeleting = false;
      state.isDeleteSuccess = true;
      state.products.map((item) => {
        if (item.id === action.payload) {
          item.reviewsCount = 0;
          return item;
        }
        return item;
      });
    },
    deleteProductReviewFail: (state) => {
      state.isDeleteDeleting = false;
      state.isDeleteSuccess = false;
      state.isGettingError = true;
    },
    // change status of product
    startChangeStatusProduct: (
      state,
      _action: PayloadAction<IUpdateReviewByProduct>
    ) => {
      state.isGettingList = true;
      state.isGettingError = false;
      state.isChangeStatusSuccess = false;
    },
    changeStatusProductSuccess: (state, action: PayloadAction<string>) => {
      state.isGettingList = false;
      state.isChangeStatusSuccess = true;
    },
    changeStatusProductFail: (state) => {
      state.isGettingList = false;
      state.isChangeStatusSuccess = false;
      state.isGettingError = true;
    },

    startExportingReviews: (
      state,
      _action: PayloadAction<string>
    ) => {
      state.isExporting = true;
    },
    exportReviewsSuccess: (state) => {
      state.isExporting = false;
    },
    exportReviewsFail: (state) => {
      state.isExporting = false;
    },
  },
});

export const { reducer, actions } = productSlice;
export const { startPaginateProducts, startExportingReviews, startChangeStatusProduct } = actions;
