import type { ApolloQueryResult } from "@apollo/client";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  Mutation,
  MutationUpdateWidgetArgs,
  Query,
  Widget,
  UpdateWidgetInput,
} from "base/graphql/types";
import { getError } from "base/helpers/error";
import { mutate, query } from "base/services/graphql";
import { showToast } from "business/toast/slice";
import { put, takeLatest } from "redux-saga/effects";
import { WIDGET_QUERY, UPDATE_WIDGET_MUTATION } from "./graphql";
import { actions } from "./slice";

function* getWidget() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<Widget>({ query: WIDGET_QUERY });
    yield put(actions.getWidgetSuccess(data.widget as Widget));
  } catch (e) {
    yield put(actions.getWidgetFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateWidget({ payload }: PayloadAction<UpdateWidgetInput>) {
  try {
    yield mutate<Mutation, MutationUpdateWidgetArgs>({
      mutation: UPDATE_WIDGET_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateWidgetSuccess());
    yield put(showToast({ content: "widgetEdited" }));
  } catch (e) {
    yield put(actions.updateWidgetFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingWidget, getWidget);
  yield takeLatest(actions.startUpdatingWidget, updateWidget);
}
