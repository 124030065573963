import type { ApolloQueryResult } from '@apollo/client';
import { PayloadAction } from '@reduxjs/toolkit';
import type {
  Mutation,
  MutationDeleteReviewsByProductArgs,
  MutationExportReviewsArgs,
  MutationUpdateReviewsStatusByProductArgs,
  Query,
  QueryShopifyProductsArgs,
  ReviewConnection,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { cleanObject } from 'base/helpers/extra';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { put, takeLatest } from 'redux-saga/effects';
import { DELETE_REVIEWS, QUERY_PRODUCTS, UPDATE_REVIEWS_STATUS } from './const';
import { EXPORT_REVIEWS_STATUS } from './graphql';
import { actions, IParams, IUpdateReviewByProduct } from './slice';

function* getListReviewInitSaga() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryShopifyProductsArgs
    >({
      query: QUERY_PRODUCTS,
    });
    yield put(actions.getListProductInitSuccess(data.products));
  } catch (e) {
    yield put(actions.getListProductInitFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* paginateProductSaga({ payload }: PayloadAction<string | undefined>) {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryShopifyProductsArgs
    >({
      query: QUERY_PRODUCTS,
      variables: cleanObject({ after: payload }),
    });
    yield put(actions.paginateProductSuccess(data.products));
  } catch (e) {
    yield put(actions.paginateProductFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* filterProductSaga({ payload }: PayloadAction<IParams>) {
  let queryParams = [];
  if (payload.status) queryParams.push(`status:${payload.status}`);
  if (payload.title) queryParams.push(`title:*${payload.title}*`);
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryShopifyProductsArgs
    >({
      query: QUERY_PRODUCTS,
      variables: {
        query: queryParams.join(' AND ')
      },
    });
    yield put(actions.filterProductSuccess(data.products));
  } catch (e) {
    yield put(actions.filterProductFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* deleteProductReviewSaga({ payload }: PayloadAction<string>) {
  try {
    yield mutate<Mutation, MutationDeleteReviewsByProductArgs>({
      mutation: DELETE_REVIEWS,
      variables: { id: payload },
    });
    yield put(actions.deleteProductReviewSuccess(payload));
    yield put(showToast({ content: 'deleteSuccess' }));
  } catch (e) {
    yield put(actions.deleteProductReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* changeStatusProductSaga({
  payload,
}: PayloadAction<IUpdateReviewByProduct>) {
  try {
    yield mutate<Mutation, MutationUpdateReviewsStatusByProductArgs>({
      mutation: UPDATE_REVIEWS_STATUS,
      variables: { input: payload },
    });
    yield put(actions.changeStatusProductSuccess(payload.id));
    yield put(showToast({ content: 'updateSuccess' }));
  } catch (e) {
    yield put(actions.changeStatusProductFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* exportReviewsSaga({
  payload,
}: PayloadAction<string>) {
  try {
    yield mutate<Mutation, MutationExportReviewsArgs>({
      mutation: EXPORT_REVIEWS_STATUS,
      variables: { shopifyProductId: payload },
    });
    yield put(actions.exportReviewsSuccess());
    yield put(showToast({ content: 'exportSuccess' }));
  } catch (e) {
    yield put(actions.exportReviewsFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingListProductInit, getListReviewInitSaga);
  yield takeLatest(actions.startPaginateProducts, paginateProductSaga);
  yield takeLatest(actions.startFilterProduct, filterProductSaga);
  yield takeLatest(actions.startDeleteProductReviews, deleteProductReviewSaga);
  yield takeLatest(actions.startChangeStatusProduct, changeStatusProductSaga);
  yield takeLatest(actions.startExportingReviews, exportReviewsSaga);
}
