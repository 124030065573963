import { Modal, TextContainer } from "@shopify/polaris";
import useTranslation from "next-translate/useTranslation";
import type { FC } from "react";

export interface IConfirmModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
  title: string;
  onAction: () => void;
  confirmText: string;
  isDestructive?: boolean;
  loading?: boolean;
}

export const ConfirmModal: FC<IConfirmModalProps> = ({
  open,
  loading,
  isDestructive,
  onClose,
  message,
  title,
  onAction,
  confirmText
}) => {
  const { t: translate } = useTranslation('common');

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      primaryAction={{
        content: confirmText,
        onAction,
        destructive: isDestructive,
        loading
      }}
      secondaryActions={[
        {
          content: translate('cancelButton'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{message}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};
