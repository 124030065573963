import { useCallback, useState } from "react";

export const useModal = (): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);

  const onClose = useCallback(() => setOpen(false), []);

  return [open, onClose, onOpen];
}
