import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface IDashboardState {
  isDismissingGetStarted: boolean;
  isGetStarted: boolean;
}

const initialState: IDashboardState = {
  isDismissingGetStarted: false,
  isGetStarted: false,
};

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    startDismissingGetStarted: (state) => {
      state.isDismissingGetStarted = true;
      state.isGetStarted = true;
    },
    dismissGetStartedSuccess: (state) => {
      state.isDismissingGetStarted = false;
    },
    dismissGetStartedFail: state => {
      state.isDismissingGetStarted = false;
    },

    setIsGetStarted: (state, action: PayloadAction<boolean>) => {
      state.isGetStarted = action.payload;
    },
  },
});

export const { reducer, actions } = dashboardSlice;
export const {
  startDismissingGetStarted,
  setIsGetStarted
} = actions;
