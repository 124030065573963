import { gql } from '@apollo/client';

export const CREATE_IMPORT_MUTATION = gql`
  mutation createImportReview($input: CreateImportReviewInput!) {
    createImportReview(input: $input)
  }
`;

export const REVIEW_IMPORT_QUERY = gql`
  query reviewImport($startAfter: String, $status: String) {
    reviewImport(status: $status, startAfter: $startAfter) {
      nodes {
        id
        product {
          id
          title
          image
          handle
        }
        importedReview
        amazonProductId
        aliProductId
        aliSellerId
        source
        stars
        media
        sort
        limit
        status
        createdAt
        updatedAt
      }
      startAfter
      hasNextPage
    }
  }
`;

export const UPDATE_REVIEW_MUTATION = gql`
  mutation updateReview($input: UpdateReviewInput!) {
    updateReview(input: $input)
  }
`;

export const CREATE_REVIEW_MUTATION = gql`
  mutation createReview($input: CreateReviewInput!) {
    createReview(input: $input)
  }
`;

export const DELETE_REVIEW_MUTATION = gql`
  mutation deleteReviews($input: DeleteReviewsInput!) {
    deleteReviews(input: $input)
  }
`;
