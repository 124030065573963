import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  HomeShowcase,
  PageShowcase,
  UpdateHomeShowcaseInput,
  UpdatePageShowcaseInput,
} from 'base/graphql/types';

export interface IHomeShowcaseState {
  isUpdating: boolean;
  isGetting: boolean;
  pageShowcase?: PageShowcase;
  homeShowcase?: HomeShowcase;
}

const initialState: IHomeShowcaseState = {
  isUpdating: false,
  isGetting: false,
};

const showcaseSlice = createSlice({
  name: 'showcaseSlice',
  initialState,
  reducers: {
    startGettingPageShowcase: (state) => {
      state.isGetting = true;
    },
    getPageShowcaseSuccess: (state, action: PayloadAction<PageShowcase>) => {
      state.isGetting = false;
      state.pageShowcase = action.payload;
    },
    getPageShowcaseFail: (state) => {
      state.isGetting = false;
    },

    startUpdatingPageShowcase: (
      state,
      _action: PayloadAction<UpdatePageShowcaseInput>
    ) => {
      state.isUpdating = true;
    },
    updatePageShowcaseSuccess: (state) => {
      state.isUpdating = false;
    },
    updatePageShowcaseFail: (state) => {
      state.isUpdating = false;
    },

    startGettingHomeShowcase: (state) => {
      state.isGetting = true;
    },
    getHomeShowcaseSuccess: (state, action: PayloadAction<HomeShowcase>) => {
      state.isGetting = false;
      state.homeShowcase = action.payload;
    },
    getHomeShowcaseFail: (state) => {
      state.isGetting = false;
    },

    startUpdatingHomeShowcase: (
      state,
      _action: PayloadAction<UpdateHomeShowcaseInput>
    ) => {
      state.isUpdating = true;
    },
    updateHomeShowcaseSuccess: (state) => {
      state.isUpdating = false;
    },
    updateHomeShowcaseFail: (state) => {
      state.isUpdating = false;
    },
  },
});

export const { reducer, actions } = showcaseSlice;
export const {
  startGettingPageShowcase,
  startUpdatingPageShowcase,
  startGettingHomeShowcase,
  startUpdatingHomeShowcase,
} = actions;
