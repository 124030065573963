import { saga as workspaceSaga } from 'business/workspace/saga';
import { saga as dashboardSaga } from 'business/dashboard/saga';
import { saga as userSaga } from 'business/account/saga';
import { saga as shopifySaga } from 'business/shopify/saga';
import { saga as reviewSaga } from 'business/reviews/saga';
import { saga as reviewImportSaga } from 'business/reviews/import/saga';
import { saga as widgetSaga } from 'business/branding/widget/saga';
import { saga as showcaseSaga } from 'business/branding/showcase/saga';
import { saga as ratingSaga } from 'business/branding/rating/saga';
import { saga as popupSaga } from 'business/branding/popup/saga';
import { saga as productSaga } from 'business/products/saga';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([
    fork(workspaceSaga),
    fork(dashboardSaga),
    fork(userSaga),
    fork(shopifySaga),
    fork(reviewSaga),
    fork(reviewImportSaga),
    fork(widgetSaga),
    fork(ratingSaga),
    fork(showcaseSaga),
    fork(popupSaga),
    fork(productSaga),
  ]);
}
