import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  CreateImportReviewInput,
  ImportReviewConnection,
  ReviewImport,
} from 'base/graphql/types';

export interface IReviewImportParams {
  statusTabIndex: number;
}

interface IStateProps {
  isGettingList: boolean;
  isGettingError: boolean;
  isCreating: boolean;
  isCreateError: boolean;
  hasNextPage: boolean;
  startAfter: string | undefined;
  params: IReviewImportParams;
  reviewImport: ReviewImport[];
}

const initialState: IStateProps = {
  isGettingList: false,
  isGettingError: false,
  isCreating: false,
  isCreateError: false,
  hasNextPage: false,
  startAfter: '',
  reviewImport: [],
  params: {
    statusTabIndex: 0,
  },
};

const reviewImportSlice = createSlice({
  name: 'reviewImportSlice',
  initialState,
  reducers: {
    // handle params
    handlePageParams: (state, action: PayloadAction<IReviewImportParams>) => {
      state.params = action.payload;
    },
    startCreatingImport: (
      state,
      _action: PayloadAction<CreateImportReviewInput>
    ) => {
      state.isCreating = true;
    },
    createImportSuccess: (state) => {
      state.isCreating = false;
    },
    createImportFail: (state) => {
      state.isCreating = false;
    },
    startGettingListImportReview: (
      state,
      action: PayloadAction<IReviewImportParams>
    ) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    getListImportReviewSuccess: (
      state,
      action: PayloadAction<ImportReviewConnection>
    ) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, startAfter } = action.payload;
      state.reviewImport = nodes;
      // state.filteredReview = nodes;
      state.hasNextPage = hasNextPage;
      state.startAfter = startAfter;
    },
    getListImportReviewFail: (state) => {
      state.isGettingError = true;
      state.isGettingList = false;
    },
  },
});

export const { reducer, actions } = reviewImportSlice;
export const { startCreatingImport } = actions;
