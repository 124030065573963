import type { ApolloQueryResult } from '@apollo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Mutation,
  MutationUpdatePopupArgs,
  Query,
  Popup,
  UpdatePopupInput,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { put, takeLatest } from 'redux-saga/effects';
import { PAGE_POPUP_QUERY, UPDATE_POPUP_MUTATION } from './graphql';
import { actions } from './slice';

function* getPopup() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<Popup>({
      query: PAGE_POPUP_QUERY,
    });
    yield put(actions.getPopupSuccess(data.popup as Popup));
  } catch (e) {
    yield put(actions.getPopupFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updatePopup({ payload }: PayloadAction<UpdatePopupInput>) {
  try {
    yield mutate<Mutation, MutationUpdatePopupArgs>({
      mutation: UPDATE_POPUP_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updatePopupSuccess());
    yield put(showToast({ content: 'popupUpdated' }));
  } catch (e) {
    yield put(actions.updatePopupFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingPopup, getPopup);
  yield takeLatest(actions.startUpdatingPopup, updatePopup);
}
