import { gql } from '@apollo/client';

export const PAGE_POPUP_QUERY = gql`
  query popup {
    popup {
      textColor
      backgroundColor
      delay
      displayTime
      includePage
      excludePage
      position
      font
      source
      productId
      reviewId
      extra
    }
  }
`;

export const UPDATE_POPUP_MUTATION = gql`
  mutation updatePopup($input: UpdatePopupInput!) {
    updatePopup(input: $input)
  }
`;
