import { gql } from "@apollo/client";

export interface IWorkspace {
  name: string;
  website?: string;
  description?: string;
  logo?: string;
  contactEmail: string;
  billingEmail: string;
  timezone?: string;
  currency?: string;
}

export const UPDATE_WORKSPACE_MUTATION = gql`
  mutation updateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input)
  }
`;

export const WORKSPACE_QUERY = gql`
  query workspace {
    workspace {
      name
      website
      description
      logo
      contactEmail
      billingEmail
      timezone
      currency
    }
  }
`;

export interface IUpdateWorkspaceInput {
  name: string;
  website?: string;
  description?: string;
  logo?: string;
  contactEmail: string;
  billingEmail: string;
  timezone?: string;
  currency?: string;
}
