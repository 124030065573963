import { Card, EmptyState, Layout, Page } from "@shopify/polaris";
import { APP_CONFIG } from "base/consts/app";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import type { FC } from "react";

export const ErrorPage: FC = () => {
  const { t: translate } = useTranslation("common");

  return (
    <>
      <Head>
        <title>
          {translate("errorPageTitle")} | {APP_CONFIG.APP_NAME}
        </title>
      </Head>
      <Page>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <EmptyState
                heading={translate("errorEmptyStateHeading")}
                action={{ content: translate("goToHomeButton"), url: "/" }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                fullWidth
              >
                <p>{translate("errorEmptyStateDescription")}</p>
              </EmptyState>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};
