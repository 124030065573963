import {
  CreateImportReviewInput,
  ReviewImportMediaEnum,
  ReviewImportSortEnum,
  ReviewImportStarsEnum,
  ReviewImportStatusEnum,
} from 'base/graphql/types';
import { FormWithShopifyProductModel } from './externalSite';

export const DEFAULT_VALUES: Omit<FormWithShopifyProductModel, 'source'> = {
  media: ReviewImportMediaEnum.All,
  sort: ReviewImportSortEnum.Recently,
  stars: ReviewImportStarsEnum.AllStars,
  limit: 100,
};

export const IMPORT_FIELDS: {
  [key: string]: keyof CreateImportReviewInput;
} = {
  AMAZON_ID: 'amazonProductId',
  ALI_PRODUCT_ID: 'aliProductId',
  ALI_SELLER_ID: 'aliSellerId',
  LIMIT: 'limit',
  MEDIA: 'media',
  SHOPIFY_PRODUCT_ID: 'product',
  SORT: 'sort',
  STARS: 'stars',
};

export const RATING_OPTIONS = [
  { label: 'All', value: ReviewImportStarsEnum.AllStars },
  { label: '5 stars', value: ReviewImportStarsEnum.FiveStar },
  { label: '4 stars', value: ReviewImportStarsEnum.FourStar },
  { label: '3 stars', value: ReviewImportStarsEnum.ThreeStar },
  { label: '2 stars', value: ReviewImportStarsEnum.TwoStar },
  { label: '1 star', value: ReviewImportStarsEnum.OneStar },
];

export const IMPORT_TAB_STATUS: any[] = [
  '',
  ReviewImportStatusEnum.Completed,
  ReviewImportStatusEnum.Error,
  ReviewImportStatusEnum.Running,
];

export const HELP_LINK = {
  FIND_ALIEXPRESS_ID:
    'https://pr-help.hoora.io/how-to-get-product-id-on-aliexpress',
  FIND_AMAZON_ID: 'https://pr-help.hoora.io/how-to-get-an-ASIN-on-amazon',
  FIND_SELLER_ALIEXPRESS_ID:
    'https://pr-help.hoora.io/how-to-get-aliexpress-seller-id',
};
