import { gql } from '@apollo/client';

export const RATING_CONFIG_QUERY = gql`
  query rating {
    rating {
      ratingIcon
      ratingIconColor
      translation {
        language
        item
      }
    }
  }
`;

export const UPDATE_CONFIG_MUTATION = gql`
  mutation updateRatingConfig($input: UpdateRatingConfigInput!) {
    updateRatingConfig(input: $input)
  }
`;
