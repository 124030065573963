import { gql } from "@apollo/client";

export const REVIEWS_QUERY = gql`
  query reviews(
    $startAfter: String
    $status: String
    $productId: ID
    $stars: [String]
  ) {
    reviews(
      status: $status
      startAfter: $startAfter
      productId: $productId
      stars: $stars
    ) {
      nodes {
        id
        product {
          id
          title
          image
          handle
        }
        authorName
        content
        email
        stars
        status
        media
        content
        country
        reply
        createdAt
        updatedAt
      }
      startAfter
      hasNextPage
    }
  }
`;

export const REVIEW_QUERY = gql`
  query review($id: ID!) {
    review(id: $id) {
      id
      product {
        id
        title
        image
        handle
      }
      country
      authorName
      content
      email
      stars
      status
      media
      content
      reply
      createdAt
    }
  }
`;

export const UPDATE_REVIEW_MUTATION = gql`
  mutation updateReview($input: UpdateReviewInput!) {
    updateReview(input: $input)
  }
`;

export const CREATE_REVIEW_MUTATION = gql`
  mutation createReview($input: CreateReviewInput!) {
    createReview(input: $input)
  }
`;


export const DELETE_REVIEW_MUTATION = gql`
  mutation deleteReviews($input: DeleteReviewsInput!) {
    deleteReviews(input: $input)
  }
`;
