import { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Badge,
  Icon,
  Navigation as PolarisNavigation,
  Tooltip,
} from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import {
  ProductsMinor,
  StarFilledMinor,
  ConversationMinor,
  HomeMinor,
  InfoMinor,
  OnlineStoreMajor,
  SettingsMinor,
  ViewMinor,
  InviteMinor,
  TemplateMinor,
} from '@shopify/polaris-icons';
import { useRouter } from 'next/router';
import { APP_CONFIG } from 'base/consts/app';
import { useUserContext } from 'base/context/user';
import { openChat } from 'base/helpers/chat';

export const Navigation: FC = () => {
  const { t: translate } = useTranslation('navigation');

  const { shopifyShop } = useUserContext();

  const { pathname, push } = useRouter();

  const [selectedBrandingNav, setSelectedBrandingNav] = useState(false);
  const [selectedReviewNav, setSelectedReviewNav] = useState(false);
  const [selectedLiveChat, setSelectLiveChat] = useState(false);

  useEffect(() => {
    setSelectedBrandingNav(pathname.startsWith('/branding'));
    setSelectedReviewNav(pathname.startsWith('/reviews'));
  }, [pathname]);

  return (
    <div className="navigation">
      <PolarisNavigation location="/">
        <PolarisNavigation.Section
          items={[
            {
              label: translate('dashboard'),
              icon: HomeMinor,
              url: '/',
              matches: pathname === '/' && !selectedBrandingNav,
              onClick: () => push('/'),
            },
            {
              label: translate('products'),
              icon: ProductsMinor,
              url: '/products',
              matches: pathname.startsWith('/products') && !selectedBrandingNav,
            },
            {
              onClick: () => setSelectedReviewNav(true),
              label: translate('reviews'),
              icon: StarFilledMinor,
              url: '#',
              selected: selectedReviewNav,
              expanded: selectedReviewNav,
              onToggleExpandedState: () => setSelectedReviewNav(true),
              subNavigationItems: [
                {
                  label: translate('reviews'),
                  url: '/reviews',
                },
                {
                  label: translate('importReview'),
                  url: '/reviews/import',
                },
              ],
            },
            {
              onClick: () => setSelectedBrandingNav(true),
              label: translate('branding'),
              icon: TemplateMinor,
              url: '#',
              selected: selectedBrandingNav,
              expanded: selectedBrandingNav,
              onToggleExpandedState: () => setSelectedBrandingNav(true),
              subNavigationItems: [
                {
                  label: translate('widget'),
                  url: '/branding/widget',
                },
                {
                  label: translate('rating'),
                  url: '/branding/rating',
                },
                {
                  label: translate('pageShowcase'),
                  url: '/branding/page-showcase',
                },
                {
                  label: translate('homeShowcase'),
                  url: '/branding/home-showcase',
                },
                {
                  label: translate('popup'),
                  url: '/branding/popup',
                },
              ],
            },
            {
              label: translate('email'),
              icon: InviteMinor,
              url: '#',
              matches: pathname.startsWith('/email'),
              badge: <Badge status="info">Coming soon</Badge>
            },
          ]}
        />
        <PolarisNavigation.Section
          fill
          separator
          title={translate('support')}
          items={[
            {
              label: translate('onlineStore'),
              icon: OnlineStoreMajor,
              url: `https://${shopifyShop}`,
              external: true,
              badge: (
                <Tooltip content={translate('viewOnlineStore')}>
                  <Icon source={ViewMinor} />
                </Tooltip>
              ),
            },
            {
              label: translate('helpCenter'),
              icon: InfoMinor,
              url: APP_CONFIG.HELP_CENTER_URL,
              external: true,
            },
            {
              label: translate('liveChat'),
              icon: ConversationMinor,
              url: '#',
              onClick: () => {
                setSelectLiveChat(true);
                openChat();
              },
              selected: selectedLiveChat,
            },
          ]}
        />
        <PolarisNavigation.Section
          items={[
            {
              label: translate('settings'),
              icon: SettingsMinor,
              url: '/settings',
              matches: pathname.startsWith('/settings'),
            },
          ]}
        />
      </PolarisNavigation>
      <style jsx>{`
        .navigation {
          :global(.Polaris-Navigation__ItemWrapper) {
            :global(.Polaris-Navigation__Text) {
              font-weight: 600;
            }
          }
          :global(.Polaris-Navigation__SecondaryNavigation) {
            :global(.Polaris-Navigation__ItemWrapper) {
              :global(.Polaris-Navigation__Text) {
                font-weight: normal;
              }
            }
          }
        }
      `}</style>
    </div>
  );
};
