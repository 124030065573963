const formatters = {
  es: new Intl.NumberFormat("es-ES"),
  en: new Intl.NumberFormat("en-EN"),
}

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'validation', 'toast', 'navigation', 'loading', 'logout', 'error', 'button', 'form'],
    'rgx:^/settings': ['setting'],
    'rgx:^/products': ['product'],
    'rgx:^/reviews': ['review'],
    '/': ['dashboard'],
    'rgx:^/branding': ['branding', 'review'],
    'rgx:^/email': ['email'],
    'rgx:^/auth': ['auth'],
    'rgx:^/branding/rating': ['rating'],
    '/faq': ['faq'],
  },
  interpolation: {
    format: (value, format, lang) => {
      if (format === 'number') return formatters[lang].format(value)
      return value
    }
  }
};
