import type { ApolloQueryResult } from '@apollo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  CreateReviewInput,
  DeleteReviewsInput,
  Mutation,
  MutationCreateReviewArgs,
  MutationDeleteReviewsArgs,
  MutationUpdateReviewArgs,
  MutationUpdateReviewsStatusArgs,
  Query,
  QueryReviewArgs,
  QueryReviewsArgs,
  Review,
  ReviewConnection,
  UpdateReviewInput,
  UpdateReviewsStatusInput,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { cleanObject } from 'base/helpers/extra';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { push } from 'connected-next-router';
import { put, takeLatest } from 'redux-saga/effects';
import { TAB_STATUS, UPDATE_REVIEWS_STATUS } from './const';
import {
  CREATE_REVIEW_MUTATION,
  DELETE_REVIEW_MUTATION,
  REVIEWS_QUERY,
  REVIEW_QUERY,
  UPDATE_REVIEW_MUTATION,
} from './graphql';
import { actions, IParams, IUpdateStatus } from './slice';

function* filterReviewListSaga({ payload }: PayloadAction<IParams>) {
  const { statusTabIndex, stars, product } = payload || {};
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryReviewsArgs
    >({
      query: REVIEWS_QUERY,
      variables: cleanObject({
        status: TAB_STATUS[statusTabIndex],
        stars,
        productId: product?.id || '',
      }),
    });
    yield put(actions.filterReviewsListSuccess(data.reviews));
  } catch (e) {
    yield put(actions.filterReviewsListError());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* getListReviewInitSaga() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryReviewsArgs
    >({
      query: REVIEWS_QUERY,
    });
    yield put(actions.getListReviewInitSuccess(data.reviews));
  } catch (e) {
    yield put(actions.getListReviewInitFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* paginateReviewSaga({ payload }: PayloadAction<string>) {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      ReviewConnection,
      QueryReviewsArgs
    >({
      query: REVIEWS_QUERY,
      variables: cleanObject({ startAfter: payload }),
    });
    yield put(actions.paginateReviewSuccess(data.reviews));
  } catch (e) {
    yield put(actions.paginateReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* changeReviewStatusSaga({
  payload,
}: PayloadAction<UpdateReviewsStatusInput>) {
  try {
    yield mutate<Mutation, MutationUpdateReviewsStatusArgs>({
      mutation: UPDATE_REVIEWS_STATUS,
      variables: { input: payload },
    });
    yield put(actions.updateReviewStatusSuccess(payload));
    yield put(showToast({ content: 'updateSuccess' }));
  } catch (e) {
    yield put(actions.updateReviewStatusError());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* deleteReviewRowsSaga({ payload }: PayloadAction<DeleteReviewsInput>) {
  try {
    yield mutate<Mutation, MutationDeleteReviewsArgs>({
      mutation: DELETE_REVIEW_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.deleteReviewRowSuccess(payload));
    yield put(showToast({ content: 'deleteSuccess' }));
  } catch (e) {
    yield put(actions.deleteReviewRowError());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* getReview({ payload }: PayloadAction<string>) {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<
      Review,
      QueryReviewArgs
    >({
      query: REVIEW_QUERY,
      variables: { id: payload },
    });
    yield put(actions.getReviewSuccess(data.review as Review));
  } catch (e) {
    yield put(actions.getReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateReview({ payload }: PayloadAction<UpdateReviewInput>) {
  try {
    yield mutate<Mutation, MutationUpdateReviewArgs>({
      mutation: UPDATE_REVIEW_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateReviewSuccess());
    yield put(showToast({ content: 'reviewEdited' }));
  } catch (e) {
    yield put(actions.updateReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* createReview({ payload }: PayloadAction<CreateReviewInput>) {
  try {
    const { data }: ApolloQueryResult<Mutation> = yield mutate<
      Mutation,
      MutationCreateReviewArgs
    >({
      mutation: CREATE_REVIEW_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.createReviewSuccess());
    yield put(showToast({ content: 'reviewCreated' }));
    yield put(push(`/reviews/edit/${data.createReview}`));
  } catch (e) {
    yield put(actions.createReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* deleteReview({ payload }: PayloadAction<string>) {
  try {
    yield mutate<Mutation, MutationDeleteReviewsArgs>({
      mutation: DELETE_REVIEW_MUTATION,
      variables: { input: { ids: [payload] } },
    });
    yield put(actions.deleteReviewSuccess());
    yield put(showToast({ content: 'reviewDeleted' }));
    yield put(push('/reviews'));
  } catch (e) {
    yield put(actions.deleteReviewFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingListReviewInit, getListReviewInitSaga);
  yield takeLatest(actions.startFilterReviewsList, filterReviewListSaga);
  yield takeLatest(actions.startPaginateReviews, paginateReviewSaga);
  yield takeLatest(actions.startUpdateReviewStatus, changeReviewStatusSaga);
  yield takeLatest(actions.startDeleteReviewRow, deleteReviewRowsSaga);
  yield takeLatest(actions.startGettingReview, getReview);
  yield takeLatest(actions.startUpdatingReview, updateReview);
  yield takeLatest(actions.startCreatingReview, createReview);
  yield takeLatest(actions.startDeletingReview, deleteReview);
}
