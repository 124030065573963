import { getError } from "base/helpers/error";
import { query } from "base/services/graphql";
import { showToast } from "business/toast/slice";
import { put, takeLatest } from "redux-saga/effects";
import { DISMISS_GET_STARTED_MUTATION } from "./graphql";
import { actions } from "./slice";

function* dismissGetStarted() {
  try {
    yield query({
      query: DISMISS_GET_STARTED_MUTATION
    });
    yield put(actions.dismissGetStartedSuccess());
  } catch (e) {
    yield put(actions.dismissGetStartedFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startDismissingGetStarted, dismissGetStarted);
}
