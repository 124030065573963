import { ApolloClient, InMemoryCache } from '@apollo/client';
import type {
  DocumentNode,
  FetchResult,
  ApolloQueryResult,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import type { User } from 'firebase/auth';
import { createUploadLink } from 'apollo-upload-client';
import { firebaseAuth } from './firebase';

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  uri: `${process.env.NEXT_PUBLIC_ADMIN_API_URL}/graphql`,
  link: createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_ADMIN_API_URL}/graphql`,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export interface IQueryParams<TData, TVariables extends OperationVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  user?: User;
  variables?: TVariables;
}

export interface IMutateParams<TData, TVariables extends OperationVariables> {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>;
  user?: User;
  variables?: TVariables;
}

export const query = async <TData, TVariables extends OperationVariables = {}>({
  query,
  user,
  variables,
}: IQueryParams<TData, TVariables>): Promise<ApolloQueryResult<TData>> => {
  let firebaseUser: User | undefined;
  if (user) {
    firebaseUser = user;
  } else if (firebaseAuth.currentUser) {
    firebaseUser = firebaseAuth.currentUser;
  }

  const token = firebaseUser ? await firebaseUser.getIdToken() : null;

  const response = await client.query({
    query,
    variables,
    context: {
      headers: {
        'x-auth-token': token,
      },
    },
  });
  return response;
};

export const mutate = async <
  TData,
  TVariables extends OperationVariables = {}
>({
  mutation,
  user,
  variables,
}: IMutateParams<TData, TVariables>): Promise<FetchResult<TData>> => {
  let firebaseUser: User | undefined;
  if (user) {
    firebaseUser = user;
  } else if (firebaseAuth.currentUser) {
    firebaseUser = firebaseAuth.currentUser;
  }

  const token = firebaseUser ? await firebaseUser.getIdToken() : null;

  const response = await client.mutate({
    mutation,
    variables,
    context: {
      headers: {
        'x-auth-token': token,
      },
    },
  });
  await client.clearStore();
  return response;
};
