import { gql } from '@apollo/client';
import type { User } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { useRouter } from 'next/router';

import { firebaseAuth } from 'base/services/firebase';
import { query } from 'base/services/graphql';
import type { CurrentWorkspace, Query } from 'base/graphql/types';
import { DisplayText, Spinner, TextStyle } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';
import TagManager from 'react-gtm-module'
import { useDispatch } from 'react-redux';
import { setIsGetStarted } from 'business/dashboard/slice';
import { setUpChat } from 'base/helpers/chat';

export const UserContext = createContext<CurrentWorkspace|null>(null);

export const GET_WORKSPACE = gql`
  query currentWorkspace {
    currentWorkspace {
      id
      workspaceName
      userName
      shopifyShop
      currency
      timezone
      userAvatar
      isGetStartedCompleted
      token
      workspacePlan
      userEmail
    }
  }
`;

export const EXCLUDE_AUTH_PATHS = ['/auth/token', '/auth/login'];

const Loading: FC = () => {
  const { t: translate } = useTranslation('loading')

  return (
    <>
      <div className="loading">
        <div>
          <Spinner size="large" />
        </div>
        <div>
          <DisplayText size="large">{translate('logInTokenHeading')}</DisplayText>
        </div>
        <div>
          <TextStyle variation="subdued">{translate('logInTokenSubheading')}</TextStyle>
        </div>
      </div>
      <style jsx>{`
        .loading {
          text-align: center;
          height: 100vh;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .loading > * {
          margin-bottom: var(--p-space-4);
        }
      `}</style>
    </>
  )
}

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const { pathname } = router;

  const [userData, setUserData] = useState<CurrentWorkspace | null>(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) return;
    if (!EXCLUDE_AUTH_PATHS.includes(pathname)) {
      firebaseAuth.onAuthStateChanged(async (user: User | null) => {
        if (!user) {
          setLoading(false);
          router.push('/auth/login');
          return;
        }
        const response = await query<Query>({ query: GET_WORKSPACE, user });

        setUserData(response.data.currentWorkspace as CurrentWorkspace);
        window.workspace = response.data.currentWorkspace;
        dispatch(setIsGetStarted(response.data.currentWorkspace.isGetStartedCompleted))
        setUpChat();
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID as string })
  }, [pathname, userData]);

  if (loading) {
    return <Loading />;
  }

  if (!userData && !EXCLUDE_AUTH_PATHS.includes(pathname)) {
    return <Loading />
  }

  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext) as CurrentWorkspace;
