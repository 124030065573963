import type { ApolloQueryResult } from '@apollo/client';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Mutation,
  MutationUpdatePageShowcaseArgs,
  Query,
  PageShowcase,
  UpdatePageShowcaseInput,
  HomeShowcase,
  UpdateHomeShowcaseInput,
  MutationUpdateHomeShowcaseArgs,
} from 'base/graphql/types';
import { getError } from 'base/helpers/error';
import { mutate, query } from 'base/services/graphql';
import { showToast } from 'business/toast/slice';
import { put, takeLatest } from 'redux-saga/effects';
import {
  HOME_SHOWCASE_QUERY,
  PAGE_SHOWCASE_QUERY,
  UPDATE_HOME_SHOWCASE_MUTATION,
  UPDATE_PAGE_SHOWCASE_MUTATION,
} from './graphql';
import { actions } from './slice';

function* getPageShowcase() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<PageShowcase>({
      query: PAGE_SHOWCASE_QUERY,
    });
    yield put(
      actions.getPageShowcaseSuccess(data.pageShowcase as PageShowcase)
    );
  } catch (e) {
    yield put(actions.getPageShowcaseFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updatePageShowcase({
  payload,
}: PayloadAction<UpdatePageShowcaseInput>) {
  try {
    yield mutate<Mutation, MutationUpdatePageShowcaseArgs>({
      mutation: UPDATE_PAGE_SHOWCASE_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updatePageShowcaseSuccess());
    yield put(showToast({ content: 'showcaseEdited' }));
  } catch (e) {
    yield put(actions.updatePageShowcaseFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* getHomeShowcase() {
  try {
    const { data }: ApolloQueryResult<Query> = yield query<HomeShowcase>({
      query: HOME_SHOWCASE_QUERY,
    });
    yield put(
      actions.getHomeShowcaseSuccess(data.homeShowcase as HomeShowcase)
    );
  } catch (e) {
    yield put(actions.getHomeShowcaseFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

function* updateHomeShowcase({
  payload,
}: PayloadAction<UpdateHomeShowcaseInput>) {
  try {
    yield mutate<Mutation, MutationUpdateHomeShowcaseArgs>({
      mutation: UPDATE_HOME_SHOWCASE_MUTATION,
      variables: { input: payload },
    });
    yield put(actions.updateHomeShowcaseSuccess());
    yield put(showToast({ content: 'showcaseEdited' }));
  } catch (e) {
    yield put(actions.updateHomeShowcaseFail());
    yield put(showToast({ content: getError(e), error: true }));
  }
}

export function* saga() {
  yield takeLatest(actions.startGettingPageShowcase, getPageShowcase);
  yield takeLatest(actions.startUpdatingPageShowcase, updatePageShowcase);
  yield takeLatest(actions.startGettingHomeShowcase, getHomeShowcase);
  yield takeLatest(actions.startUpdatingHomeShowcase, updateHomeShowcase);
}
