import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { UpdateUserInput, User } from 'base/graphql/types';

export interface IUserState {
  isUpdating: boolean;
  isGetting: boolean;
  user?: User;
}

const initialState: IUserState = {
  isUpdating: false,
  isGetting: false,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    startGettingUser: (state) => {
      state.isGetting = true;
    },
    getUserSuccess: (state, action: PayloadAction<User>) => {
      state.isGetting = false;
      state.user = action.payload;
    },
    getUserFail: state => {
      state.isGetting = false
    },

    startUpdatingUser: (state, _action: PayloadAction<UpdateUserInput>) => {
      state.isUpdating = true;
    },
    updateUserSuccess: (state) => {
      state.isUpdating = false;
    },
    updateUserFail: state => {
      state.isUpdating = false
    },
  },
});

export const { reducer, actions } = userSlice;
export const { startGettingUser, startUpdatingUser } = actions;
