import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ShopifyCollection, ShopifyProduct } from 'base/graphql/types';
import type { IPaginateCollectionsSuccessPayload, IPaginateProductsSuccessPayload, IStartPaginatingCollectionsPayload, IStartPaginatingProductsPayload } from './types';

export interface IShopifyState {
  productQuery?: string;
  collectionQuery?: string;
  isProductPaginating: boolean;
  isCollectionPaginating: boolean;
  collections: ShopifyCollection[];
  products: ShopifyProduct[];
  hasNextProductPage: boolean;
  hasNextCollectionPage: boolean;
  productStartAfter?: string | null;
  collectionStartAfter?: string | null;
}

const initialState: IShopifyState = {
  isProductPaginating: false,
  isCollectionPaginating: false,
  collections: [],
  products: [],
  hasNextProductPage: false,
  hasNextCollectionPage: false,
  productStartAfter: null,
  collectionStartAfter: null,
};

const shopifySlice = createSlice({
  name: 'shopifySlice',
  initialState,
  reducers: {
    changeProductQuery: (state, action: PayloadAction<string|undefined>) => {
      state.productQuery = action.payload;
    },
    changeCollectionQuery: (state, action: PayloadAction<string|undefined>) => {
      state.collectionQuery = action.payload;
    },
    startPaginatingShopifyProducts: (state, _action: PayloadAction<IStartPaginatingProductsPayload>) => {
      state.isProductPaginating = true;
    },
    paginateShopifyProductsSuccess: (state, action: PayloadAction<IPaginateProductsSuccessPayload>) => {
      state.isProductPaginating = false;
      if (action.payload.initial) {
        state.products = action.payload.items as ShopifyProduct[];
      } else {
        state.products = [...state.products, ...action.payload.items as ShopifyProduct[]];
      }
      state.hasNextProductPage = action.payload.hasNextPage;
      state.productStartAfter = action.payload.after;
    },
    paginateShopifyProductsFail: state => {
      state.isProductPaginating = false
    },
    startPaginatingShopifyCollections: (state, _action: PayloadAction<IStartPaginatingCollectionsPayload>) => {
      state.isCollectionPaginating = true;
    },
    paginateShopifyCollectionsSuccess: (state, action: PayloadAction<IPaginateCollectionsSuccessPayload>) => {
      state.isCollectionPaginating = false;
      if (action.payload.initial) {
        state.collections = action.payload.items as ShopifyCollection[];
      } else {
        state.collections = [...state.collections, ...action.payload.items as ShopifyCollection[]];
      }
      state.hasNextCollectionPage = action.payload.hasNextPage;
      state.productStartAfter = action.payload.after;
    },
    paginateShopifyCollectionsFail: state => {
      state.isCollectionPaginating = false
    },
  },
});

export const { reducer, actions } = shopifySlice;
export const {
  startPaginatingShopifyProducts,
  startPaginatingShopifyCollections,
  changeProductQuery,
  changeCollectionQuery,
} = actions;
