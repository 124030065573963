import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type {
  CreateReviewInput,
  DeleteReviewsInput,
  Product,
  Review,
  ReviewConnection,
  ReviewStatusEnum,
  UpdateReviewInput,
  UpdateReviewsStatusInput,
} from 'base/graphql/types';
import { uniqBy } from 'lodash';
import { TAB_STATUS } from './const';

export interface IParams {
  product?: Product;
  stars?: string[];
  statusTabIndex: number;
}

export interface IUpdateStatus {
  ids: string[];
  status: ReviewStatusEnum;
}
interface IStateProps {
  startAfter?: string;
  params: IParams;
  reviews: Review[];
  tableSelectedItems: string[];
  hasNextPage?: boolean;
  isGettingList?: boolean;
  isGettingError?: boolean;
  isPaginating: boolean;
  isUpdating: boolean;
  isCreating: boolean;
  isGetting: boolean;
  review?: Review;
  isDeleting: boolean;
  isUpdatingStatusLoading: boolean;
  isUpdatingStatusError: boolean;
  isDeleteRowsLoading: boolean;
  isDeleteRowsError: boolean;
}

const initialState: IStateProps = {
  isGettingList: false,
  isGettingError: false,
  tableSelectedItems: [],
  isUpdatingStatusLoading: false,
  isUpdatingStatusError: false,
  isDeleteRowsLoading: false,
  isDeleteRowsError: false,
  hasNextPage: false,
  startAfter: '',
  reviews: [],
  params: {
    statusTabIndex: 0,
    product: undefined,
  },
  isUpdating: false,
  isPaginating: false,
  isCreating: false,
  isGetting: false,
  isDeleting: false,
};

const reviewSlice = createSlice({
  name: 'reviewSlice',
  initialState,
  reducers: {
    startPaginateReviews: (state, _action: PayloadAction<string>) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    paginateReviewSuccess: (state, action: PayloadAction<ReviewConnection>) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, startAfter } = action.payload;
      state.reviews = nodes?.length
        ? uniqBy([...state.reviews, ...nodes], 'id')
        : [];
      state.hasNextPage = hasNextPage;
      state.startAfter = startAfter;
    },
    paginateReviewFail: (state) => {
      state.isGettingList = false;
      state.isGettingError = true;
    },
    // handle params
    handlePageParams: (state, action: PayloadAction<IParams>) => {
      state.params = action.payload;
    },
    // handle rows selected in table
    handleTableSelectItem: (state, action: PayloadAction<string[]>) => {
      state.tableSelectedItems = action.payload;
    },

    // start update status via api
    startUpdateReviewStatus: (
      state,
      action: PayloadAction<UpdateReviewsStatusInput>
    ) => {
      state.isUpdatingStatusLoading = true;
      state.isUpdatingStatusError = false;
    },
    updateReviewStatusSuccess: (
      state,
      action: PayloadAction<UpdateReviewsStatusInput>
    ) => {
      state.isUpdatingStatusLoading = false;
      state.tableSelectedItems = [];
      const { ids, status } = action.payload;
      const filterReviewLocal = state.reviews?.map((reviewItem) => {
        if (ids.includes(reviewItem.id)) {
          return {
            ...reviewItem,
            status,
          };
        }
        return reviewItem;
      });

      if (state.params.statusTabIndex) {
        const currentTabStatus = TAB_STATUS[state.params.statusTabIndex];
        state.reviews = filterReviewLocal.filter(
          ({ status }) => status === currentTabStatus
        );
      } else {
        state.reviews = filterReviewLocal;
      }
    },
    updateReviewStatusError: (state) => {
      state.isUpdatingStatusLoading = false;
      state.isUpdatingStatusError = true;
    },

    // start delete review via api
    startDeleteReviewRow: (
      state,
      action: PayloadAction<DeleteReviewsInput>
    ) => {
      state.isDeleteRowsLoading = true;
      state.isDeleteRowsError = false;
    },
    deleteReviewRowSuccess: (
      state,
      action: PayloadAction<DeleteReviewsInput>
    ) => {
      state.isDeleteRowsLoading = false;
      state.tableSelectedItems = [];
      const { ids } = action.payload;

      const removeIdsFromList = state.reviews?.filter(
        ({ id }) => !ids.includes(id)
      );

      const lastItem = removeIdsFromList[removeIdsFromList?.length - 1]

      if (state.hasNextPage) state.startAfter = lastItem.id

      state.reviews = removeIdsFromList;
    },
    deleteReviewRowError: (state) => {
      state.isDeleteRowsLoading = false;
      state.isDeleteRowsError = true;
    },

    // start filter reviews
    startFilterReviewsList: (state, action: PayloadAction<IParams>) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    filterReviewsListSuccess: (
      state,
      action: PayloadAction<ReviewConnection>
    ) => {
      state.isGettingList = false;

      const { nodes, hasNextPage, startAfter } = action.payload;
      state.reviews = nodes;
      state.hasNextPage = hasNextPage;
      state.startAfter = startAfter;
    },
    filterReviewsListError: (state) => {
      state.isGettingList = false;
      state.isGettingError = true;
    },
    // get reviews init
    startGettingListReviewInit: (state) => {
      state.isGettingList = true;
      state.isGettingError = false;
    },
    getListReviewInitSuccess: (
      state,
      action: PayloadAction<ReviewConnection>
    ) => {
      state.isGettingList = false;
      const { nodes, hasNextPage, startAfter } = action.payload;
      state.reviews = nodes;
      // state.filteredReview = nodes;
      state.hasNextPage = hasNextPage;
      state.startAfter = startAfter;
    },
    getListReviewInitFail: (state) => {
      state.isGettingError = true;
      state.isGettingList = false;
    },
    startGettingReview: (state, _action: PayloadAction<string>) => {
      state.isGetting = true;
    },
    getReviewSuccess: (state, action: PayloadAction<Review>) => {
      state.isGetting = false;
      state.review = action.payload;
    },
    getReviewFail: (state) => {
      state.isGetting = false;
    },

    startUpdatingReview: (state, _action: PayloadAction<UpdateReviewInput>) => {
      state.isUpdating = true;
    },
    updateReviewSuccess: (state) => {
      state.isUpdating = false;
    },
    updateReviewFail: (state) => {
      state.isUpdating = false;
    },

    startCreatingReview: (state, _action: PayloadAction<CreateReviewInput>) => {
      state.isCreating = true;
    },
    createReviewSuccess: (state) => {
      state.isCreating = false;
    },
    createReviewFail: (state) => {
      state.isCreating = false;
    },

    startDeletingReview: (state, _action: PayloadAction<string>) => {
      state.isDeleting = true;
    },
    deleteReviewSuccess: (state) => {
      state.isDeleting = false;
    },
    deleteReviewFail: (state) => {
      state.isDeleting = false;
    },
  },
});

export const { reducer, actions } = reviewSlice;
export const {
  startPaginateReviews,
  startGettingReview,
  startCreatingReview,
  startUpdatingReview,
  startDeletingReview,
} = actions;
