import { gql } from '@apollo/client';
import {
  CreateReviewInput,
  ReviewStatusEnum,
  UpdateReviewInput,
} from 'base/graphql/types';

export const QUERY_REVIEWS = gql`
  query reviews(
    $startAfter: String
    $status: String
    $productId: ID
    $stars: [String]
  ) {
    reviews(
      status: $status
      startAfter: $startAfter
      productId: $productId
      stars: $stars
    ) {
      nodes {
        id
        product {
          id
          title
          image
          handle
        }
        authorName
        content
        email
        stars
        status
        media
        content
        reply
        createdAt
        updatedAt
        country
      }
      startAfter
      hasNextPage
    }
  }
`;

export const QUERY_SHOPIFY_PRODUCTS = gql`
  query shopifyProducts($query: String, $after: String) {
    shopifyProducts(query: $query, after: $after) {
      items {
        id
        title
        image
        handle
      }
      after
      hasNextPage
    }
  }
`;

export const DELETE_REVIEWS = gql`
  mutation deleteReviews($input: DeleteReviewsInput!) {
    deleteReviews(input: $input)
  }
`;

export const UPDATE_REVIEWS_STATUS = gql`
  mutation updateReviewsStatus($input: UpdateReviewsStatusInput!) {
    updateReviewsStatus(input: $input)
  }
`;

export const IMPORT_FILE_MUTATION = gql`
  mutation importReview($input: ImportReviewsInput!) {
    importReview(input: $input)
  }
`;

export const FORM_FIELDS: {
  [key: string]: keyof CreateReviewInput | keyof Omit<UpdateReviewInput, 'id'>;
} = {
  AUTHOR_NAME: 'authorName',
  EMAIL: 'email',
  COUNTRY: 'country',
  STARS: 'stars',
  DATE: 'createdAt',
  CONTENT: 'content',
  REPLY: 'reply',
  STATUS: 'status',
  MEDIA: 'media',
  PRODUCT: 'product',
};

export const DEFAULT_VALUES:
  | Omit<CreateReviewInput, 'product'>
  | Omit<UpdateReviewInput, 'id' | 'product'> = {
  authorName: '',
  content: '',
  country: '',
  // product: {} as any, // TODO: Change this product
  createdAt: new Date(),
  email: '',
  media: [],
  reply: '',
  stars: 5,
  status: ReviewStatusEnum.Approved,
};

export const RATING_OPTIONS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const TAB_STATUS: any[] = [
  '',
  ReviewStatusEnum.Approved,
  ReviewStatusEnum.Pending,
  ReviewStatusEnum.Spam,
  ReviewStatusEnum.Hidden,
];

export const TAB_STATUS_OPTIONS = {
  APPROVED: ReviewStatusEnum.Approved,
  PENDING: ReviewStatusEnum.Pending,
  SPAM: ReviewStatusEnum.Spam,
  HIDDEN: ReviewStatusEnum.Hidden,
};

export const ROW_NUMBER_TO_ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
];
