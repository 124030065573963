import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Popup, UpdatePopupInput } from 'base/graphql/types';

export interface IPopupState {
  isUpdating: boolean;
  isGetting: boolean;
  popup?: Popup;
}

const initialState: IPopupState = {
  isUpdating: false,
  isGetting: false,
};

const popupSlice = createSlice({
  name: 'popupSlice',
  initialState,
  reducers: {
    startGettingPopup: (state) => {
      state.isGetting = true;
    },
    getPopupSuccess: (state, action: PayloadAction<Popup>) => {
      state.isGetting = false;
      state.popup = action.payload;
    },
    getPopupFail: (state) => {
      state.isGetting = false;
    },

    startUpdatingPopup: (state, _action: PayloadAction<UpdatePopupInput>) => {
      state.isUpdating = true;
    },
    updatePopupSuccess: (state) => {
      state.isUpdating = false;
    },
    updatePopupFail: (state) => {
      state.isUpdating = false;
    },
  },
});

export const { reducer, actions } = popupSlice;
export const { startGettingPopup, startUpdatingPopup } = actions;
