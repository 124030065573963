export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Object: any;
  Upload: any;
};

export type CreateImportReviewInput = {
  aliProductId?: InputMaybe<Scalars['String']>;
  aliSellerId?: InputMaybe<Scalars['String']>;
  amazonProductId?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  media?: InputMaybe<ReviewImportMediaEnum>;
  product: ShopifyProductInput;
  sort?: InputMaybe<ReviewImportSortEnum>;
  source: ReviewImportSourceEnum;
  stars: ReviewImportStarsEnum;
};

export type CreateReviewInput = {
  authorName: Scalars['String'];
  content: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  media?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  product: ShopifyProductInput;
  reply?: InputMaybe<Scalars['String']>;
  stars: Scalars['Int'];
  status: ReviewStatusEnum;
};

export type CurrentWorkspace = {
  __typename?: 'CurrentWorkspace';
  currency: Scalars['String'];
  id: Scalars['ID'];
  isGetStartedCompleted: Scalars['Boolean'];
  shopifyShop?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  token: Scalars['String'];
  userAvatar?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  workspaceName: Scalars['String'];
  workspacePlan: Scalars['String'];
};

export type DeleteReviewsInput = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Email = {
  __typename?: 'Email';
  remindEmail: RemindEmail;
  requestEmail: RequestEmail;
  thankEmail: ThankEmail;
};

export type HomeShowcase = {
  __typename?: 'HomeShowcase';
  extra?: Maybe<Scalars['Object']>;
  flagShape: Scalars['String'];
  font?: Maybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  primaryColor: Scalars['String'];
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  reviewId?: Maybe<Array<Maybe<Scalars['String']>>>;
  source: HomeShowcaseSourceEnum;
  verifiedBadgeColor: Scalars['String'];
};

export enum HomeShowcaseSourceEnum {
  Latest = 'Latest',
  Manual = 'Manual',
  Products = 'Products'
}

export type ImportReviewConnection = {
  __typename?: 'ImportReviewConnection';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<Maybe<ReviewImport>>;
  startAfter?: Maybe<Scalars['String']>;
};

export type ImportReviewsInput = {
  file: Scalars['Upload'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createImportReview: Scalars['ID'];
  createReview: Scalars['ID'];
  deleteReviews: Scalars['Boolean'];
  deleteReviewsByProduct: Scalars['Boolean'];
  exportReviews: Scalars['Boolean'];
  importReview: Scalars['Boolean'];
  updateHomeShowcase: Scalars['Boolean'];
  updatePageShowcase: Scalars['Boolean'];
  updatePopup: Scalars['Boolean'];
  updateRatingConfig: Scalars['Boolean'];
  updateRemindEmail: Scalars['Boolean'];
  updateRequestEmail: Scalars['Boolean'];
  updateReview: Scalars['Boolean'];
  updateReviewsStatus: Scalars['Boolean'];
  updateReviewsStatusByProduct: Scalars['Boolean'];
  updateThankEmail: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateWidget: Scalars['Boolean'];
  updateWorkspace: Scalars['Boolean'];
};


export type MutationCreateImportReviewArgs = {
  input: CreateImportReviewInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationDeleteReviewsArgs = {
  input: DeleteReviewsInput;
};


export type MutationDeleteReviewsByProductArgs = {
  id: Scalars['ID'];
};


export type MutationExportReviewsArgs = {
  shopifyProductId: Scalars['String'];
};


export type MutationImportReviewArgs = {
  input: ImportReviewsInput;
};


export type MutationUpdateHomeShowcaseArgs = {
  input: UpdateHomeShowcaseInput;
};


export type MutationUpdatePageShowcaseArgs = {
  input: UpdatePageShowcaseInput;
};


export type MutationUpdatePopupArgs = {
  input: UpdatePopupInput;
};


export type MutationUpdateRatingConfigArgs = {
  input: UpdateRatingConfigInput;
};


export type MutationUpdateRemindEmailArgs = {
  input: UpdateRemindEmailInput;
};


export type MutationUpdateRequestEmailArgs = {
  input: UpdateRequestEmailInput;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


export type MutationUpdateReviewsStatusArgs = {
  input: UpdateReviewsStatusInput;
};


export type MutationUpdateReviewsStatusByProductArgs = {
  input?: InputMaybe<UpdateReviewsStatusByProductInput>;
};


export type MutationUpdateThankEmailArgs = {
  input: UpdateThankEmailInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};

export type PageShowcase = {
  __typename?: 'PageShowcase';
  extra?: Maybe<Scalars['Object']>;
  flagShape: Scalars['String'];
  font?: Maybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  pageName: Scalars['String'];
  primaryColor: Scalars['String'];
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  reviewId?: Maybe<Array<Maybe<Scalars['String']>>>;
  source: PageShowcaseSourceEnum;
  verifiedBadgeColor: Scalars['String'];
};

export enum PageShowcaseSourceEnum {
  Latest = 'Latest',
  Manual = 'Manual',
  Products = 'Products'
}

export type Popup = {
  __typename?: 'Popup';
  backgroundColor: Scalars['String'];
  delay: Scalars['String'];
  displayTime: Scalars['String'];
  excludePage?: Maybe<Array<Maybe<Scalars['String']>>>;
  extra?: Maybe<Scalars['Object']>;
  font: Scalars['String'];
  includePage?: Maybe<Array<Maybe<Scalars['String']>>>;
  position: PopupPositionEnum;
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewId?: Maybe<Array<Maybe<Scalars['String']>>>;
  source: PopupSourceEnum;
  textColor: Scalars['String'];
};

export enum PopupPositionEnum {
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight'
}

export enum PopupSourceEnum {
  Latest = 'Latest',
  Manual = 'Manual',
  Products = 'Products'
}

export type Product = {
  __typename?: 'Product';
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  reviewsCount: Scalars['Int'];
  title: Scalars['String'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  after?: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
  nodes: Array<Maybe<Product>>;
};

export type Query = {
  __typename?: 'Query';
  currentWorkspace: CurrentWorkspace;
  email: Email;
  homeShowcase: HomeShowcase;
  pageShowcase: PageShowcase;
  popup: Popup;
  products: ProductConnection;
  rating: RatingConfig;
  review?: Maybe<Review>;
  reviewImport: ImportReviewConnection;
  reviews: ReviewConnection;
  shopifyCollections: ShopifyCollectionPagination;
  shopifyProducts: ShopifyProductPagination;
  user: User;
  widget: Widget;
  workspace: Workspace;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryReviewArgs = {
  id: Scalars['ID'];
};


export type QueryReviewImportArgs = {
  startAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryReviewsArgs = {
  productId?: InputMaybe<Scalars['ID']>;
  stars?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryShopifyCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryShopifyProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type RatingConfig = {
  __typename?: 'RatingConfig';
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  translation: Array<Maybe<TranslationResource>>;
};

export type RemindEmail = {
  __typename?: 'RemindEmail';
  delay: Scalars['Int'];
  descriptionTextColor: Scalars['String'];
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<Maybe<TranslationResource>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export type RequestEmail = {
  __typename?: 'RequestEmail';
  delay: Scalars['Int'];
  descriptionTextColor: Scalars['String'];
  event: RequestEventEnum;
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<Maybe<TranslationResource>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export enum RequestEventEnum {
  ArchiveOrder = 'ArchiveOrder',
  CreateOrder = 'CreateOrder',
  FulfillOrder = 'FulfillOrder'
}

export type Review = {
  __typename?: 'Review';
  authorName: Scalars['String'];
  content: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
  product?: Maybe<ShopifyProduct>;
  reply?: Maybe<Scalars['String']>;
  reviewMedia: Array<Maybe<ReviewMedia>>;
  stars: Scalars['Int'];
  status: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  hasNextPage: Scalars['Boolean'];
  nodes: Array<Maybe<Review>>;
  startAfter?: Maybe<Scalars['String']>;
};

export type ReviewImport = {
  __typename?: 'ReviewImport';
  aliProductId?: Maybe<Scalars['String']>;
  aliSellerId?: Maybe<Scalars['String']>;
  amazonProductId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  importedReview?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  media?: Maybe<ReviewImportMediaEnum>;
  product?: Maybe<ShopifyProduct>;
  sort?: Maybe<ReviewImportSortEnum>;
  source: ReviewImportSourceEnum;
  stars?: Maybe<ReviewImportStarsEnum>;
  status?: Maybe<ReviewImportStatusEnum>;
  updatedAt: Scalars['Date'];
};

export enum ReviewImportMediaEnum {
  All = 'All',
  MediaOnly = 'MediaOnly'
}

export enum ReviewImportSortEnum {
  Helpful = 'Helpful',
  Recently = 'Recently'
}

export enum ReviewImportSourceEnum {
  AliExpress = 'AliExpress',
  Amazon = 'Amazon',
  Csv = 'CSV'
}

export enum ReviewImportStarsEnum {
  AllStars = 'ALL_STARS',
  FiveStar = 'FIVE_STAR',
  FourStar = 'FOUR_STAR',
  OneStar = 'ONE_STAR',
  ThreeStar = 'THREE_STAR',
  TwoStar = 'TWO_STAR'
}

export enum ReviewImportStatusEnum {
  Completed = 'Completed',
  Error = 'Error',
  Running = 'Running'
}

export type ReviewMedia = {
  __typename?: 'ReviewMedia';
  source: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  type: ReviewMediaTypeEnum;
};

export enum ReviewMediaTypeEnum {
  Image = 'Image',
  Video = 'Video'
}

export enum ReviewStatusEnum {
  Approved = 'Approved',
  Hidden = 'Hidden',
  Pending = 'Pending',
  Spam = 'Spam'
}

export type ShopifyCollection = {
  __typename?: 'ShopifyCollection';
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ShopifyCollectionPagination = {
  __typename?: 'ShopifyCollectionPagination';
  after?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  items: Array<Maybe<ShopifyCollection>>;
};

export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ShopifyProductInput = {
  handle: Scalars['String'];
  id: Scalars['ID'];
  image?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ShopifyProductPagination = {
  __typename?: 'ShopifyProductPagination';
  after?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  items: Array<Maybe<ShopifyProduct>>;
};

export type ThankEmail = {
  __typename?: 'ThankEmail';
  descriptionTextColor: Scalars['String'];
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<Maybe<TranslationResource>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export type TranslationResource = {
  __typename?: 'TranslationResource';
  item: Scalars['Object'];
  language: Scalars['String'];
};

export type TranslationResourceInput = {
  item: Scalars['Object'];
  language: Scalars['String'];
};

export type UpdateHomeShowcaseInput = {
  extra?: InputMaybe<Scalars['Object']>;
  flagShape: Scalars['String'];
  font?: InputMaybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  primaryColor: Scalars['String'];
  productId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  reviewId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source: HomeShowcaseSourceEnum;
  verifiedBadgeColor: Scalars['String'];
};

export type UpdatePageShowcaseInput = {
  extra?: InputMaybe<Scalars['Object']>;
  flagShape: Scalars['String'];
  font?: InputMaybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  pageName: Scalars['String'];
  primaryColor: Scalars['String'];
  productId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  reviewId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source: PageShowcaseSourceEnum;
  verifiedBadgeColor: Scalars['String'];
};

export type UpdatePopupInput = {
  backgroundColor: Scalars['String'];
  delay: Scalars['String'];
  displayTime: Scalars['String'];
  excludePage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  extra?: InputMaybe<Scalars['Object']>;
  font: Scalars['String'];
  includePage?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  position: PopupPositionEnum;
  productId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reviewId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  source: PopupSourceEnum;
  textColor: Scalars['String'];
};

export type UpdateRatingConfigInput = {
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  translation: Array<InputMaybe<TranslationResourceInput>>;
};

export type UpdateRemindEmailInput = {
  delay: Scalars['Int'];
  descriptionTextColor: Scalars['String'];
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<InputMaybe<TranslationResourceInput>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export type UpdateRequestEmailInput = {
  delay: Scalars['Int'];
  descriptionTextColor: Scalars['String'];
  event: RequestEventEnum;
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<InputMaybe<TranslationResourceInput>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export type UpdateReviewInput = {
  authorName: Scalars['String'];
  content: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  media?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  product: ShopifyProductInput;
  reply?: InputMaybe<Scalars['String']>;
  stars: Scalars['Int'];
  status: Scalars['String'];
};

export type UpdateReviewsStatusByProductInput = {
  id: Scalars['String'];
  status: ReviewStatusEnum;
};

export type UpdateReviewsStatusInput = {
  ids: Array<InputMaybe<Scalars['String']>>;
  status: ReviewStatusEnum;
};

export type UpdateThankEmailInput = {
  descriptionTextColor: Scalars['String'];
  footerTextColor: Scalars['String'];
  headingTextColor: Scalars['String'];
  helpTextColor: Scalars['String'];
  logoUrl?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  translation: Array<InputMaybe<TranslationResourceInput>>;
  writeReviewButtonBackground: Scalars['String'];
  writeReviewButtonColor: Scalars['String'];
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type UpdateWidgetInput = {
  flagShape: Scalars['String'];
  font?: InputMaybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  primaryColor: Scalars['String'];
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  translation: Array<InputMaybe<TranslationResourceInput>>;
  verifiedBadgeColor: Scalars['String'];
};

export type UpdateWorkspaceInput = {
  billingEmail: Scalars['String'];
  contactEmail: Scalars['String'];
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type Widget = {
  __typename?: 'Widget';
  flagShape: Scalars['String'];
  font?: Maybe<Scalars['String']>;
  hasFlags: Scalars['Boolean'];
  hasReviewDate: Scalars['Boolean'];
  hasReviewPhotos: Scalars['Boolean'];
  hasVerifiedBadge: Scalars['Boolean'];
  primaryColor: Scalars['String'];
  ratingIcon: Scalars['String'];
  ratingIconColor: Scalars['String'];
  reviewDateFormat: Scalars['String'];
  translation: Array<Maybe<TranslationResource>>;
  verifiedBadgeColor: Scalars['String'];
};

export type Workspace = {
  __typename?: 'Workspace';
  billingEmail: Scalars['String'];
  contactEmail: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};
